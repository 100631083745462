import { FC } from 'react';

import './DataSourceExistingItem.css';

export type DataSourceExistingItemProps = {
  engine: string;
  config: Record<string, string>;
  onClick: () => void;
};

export const DataSourceExistingItem: FC<DataSourceExistingItemProps> = ({
  engine,
  config,
  onClick,
}) => {
  return (
    <div className="d-flex data-source-existing-item-wrapper" onClick={onClick}>
      <div className="col-3">
        <h3>Engine</h3>
        <p>{engine}</p>
      </div>
      <div className="col-3">
        <h3>Config</h3>
        <p>{JSON.stringify(config)}</p>
      </div>
    </div>
  );
};

import { DataSourceExistingItem } from '../../components/DataSourceExistingItem/DataSourceExistingItem';
import { DataSourceOptionItem } from '../../components/DataSourceOptionItem/DataSourceOptionItem';
import { fetchDataSources } from '../../queries/fetchDataSources/fetchDataSources';
import { Typography } from '../../ui-kit/components/Typography/Typography';

export const DataSources = () => {
  const { dataSources = { connections: [] } } = fetchDataSources();

  console.log('===========================')
  console.log({ dataSources })
  console.log('===========================')
  return (
    <div className="p-5">
      <div className="d-flex flex-column">
        <Typography size={'xxl'} weight={'rg'} className="mb-4">
          Data Sources
        </Typography>
        <Typography size={'lg'} weight={'md'} className="mb-2">
          Databases
        </Typography>
        <Typography size={'xs'} weight={'rg'}>
          Use your database to connect to the data sources we have available
        </Typography>
      </div>

      <div className="row mt-4">
        <div className="col-4">
          <DataSourceOptionItem
            title="Postgres"
            description="Self-hosted Postgres database"
            onClick={() => {
              console.log('Select type');
            }}
          />
        </div>
        <div className="col-4">
          <DataSourceOptionItem
            title="MySQL"
            description="Self-hosted MySQL database"
            onClick={() => {
              console.log('Select type');
            }}
          />
        </div>
      </div>

      {dataSources.connections.length ? (
        <div className="row">
          {dataSources.connections.map(({ id, engine, config }) => (
            <div className="col-12">
              <DataSourceExistingItem
                key={id}
                engine={engine}
                config={config}
                onClick={() => {}}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

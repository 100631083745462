import { FC } from 'react';

import './DataSourceOptionItem.css';
import { Typography } from '../../ui-kit/components/Typography/Typography';

export type DataSourceOptionItemProps = {
  title: string;
  description?: string;
  icon?: any;
  onClick: () => void;
};

export const DataSourceOptionItem: FC<DataSourceOptionItemProps> = ({ title, description }) => {
  return (
    <div className="d-flex flex-column p-4 data-source-option-item-wrapper">
      <Typography size={'sm'} weight={'md'} className="mb-2">
        {title}
      </Typography>
      <Typography size={'xxs'} weight={'rg'}>
        {description}
      </Typography>
    </div>
  );
};

import { ConnectionManagement } from '../ConnectionManagement/ConnectionManagement';
import { Dashboard } from '../Dashboard/Dashboard';
import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';

import './Screen.css';

export const Screen = () => {
  return (
    <div className="d-flex flex-row">
      <Sidebar />
      <div className="screen-content-wrapper">
        <Header />
        {/* <Dashboard /> */}
        <ConnectionManagement />
      </div>
    </div>
  );
};

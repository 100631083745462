import { FC } from 'react';
import './Button.css';

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button: FC<ButtonProps> = (props) => {
  return <button {...props} className="button rounded px-3 py-2" />;
};

import { FC } from 'react';

export type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

export const Select: FC<SelectProps> = (props) => {
  return <select {...props} />;
};

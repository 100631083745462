import { Typography } from '../../ui-kit/components/Typography/Typography';
import './FlightDeck.css';

export const FlightDeck = () => {
  return (
    <div className="flight-deck-wrapper d-flex align-items-center">
      <Typography size={'xs'} weight={'md'}>
        Analytics Workspace
      </Typography>
    </div>
  );
};

import { FC, ReactNode } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';

export const queryCache = new QueryCache();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // cacheTime: 1000 * 60 * 60 * 24, // 24 hours,
      // staleTime: 1000 * 60 * 60 * 24,
    },
  },
  queryCache,
});

// const sessionStoragePersistor = createWebStoragePersistor({
//   storage: window.sessionStorage,
// });

// persistQueryClient({
//   queryClient,
//   // persistor: sessionStoragePersistor,
// });

export const ReactQueryClientProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

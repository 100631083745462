import { useState } from 'react';

export type ConnectionConfig = {
  host: string;
  port: number;
  database: string;
  user: string;
  password: string;
  engine?: string;
};

export const useConnectionManagement = () => {
  const [connection, setConnection] = useState<ConnectionConfig>({
    host: '',
    port: 3000,
    database: '',
    user: '',
    password: '',
    engine: ''
  });

  return {
    connection,
    setConnection,
  };
};

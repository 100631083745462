import { useQuery } from 'react-query';
// import { DataSource } from '../../types/datasources';

type DatabaseConnectionsResponse = {
  connections: Connection[];
}

type Connection = {
  id: string;
  engine: string;
  config: Record<string, string>;
};

const doFetchDataSources = () => {
  // return fetch('http://localhost:4000/database_connections').then<DataSource[]>((response) =>
  return fetch('http://localhost:4000/database_connections').then<DatabaseConnectionsResponse>((response) =>
    response.json(),
  );
};

export const fetchDataSources = () => {
  // TODO: Figure out what's wrong with ESLint here
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, isFetching, isError } = useQuery('fetchDataSources', doFetchDataSources);

  return {
    dataSources: data,
    isFetching,
    isError,
  };
};

import { Typography } from '../../ui-kit/components/Typography/Typography';
import './Header.css';

export const Header = () => {
  return (
    <div className="d-flex align-items-center header-wrapper">
      <Typography size={'xxs'} weight={'md'}>
        Dashboard
      </Typography>
    </div>
  );
};

import { Typography } from '../../ui-kit/components/Typography/Typography';

export const Navigation = () => {
  return (
    <div className="d-flex flex-column p-4">
      <Typography size={'xs'} weight={'md'} className="mb-3">
        Dashboard
      </Typography>

      <Typography size={'xs'} weight={'md'}>
        Connections
      </Typography>
    </div>
  );
};

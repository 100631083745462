import { FC, ReactNode } from 'react';
import cx from 'classnames';

import { FontSize, FontWeight, TypographyElement } from './Typography.types';

export type TypographyProps = {
  size: FontSize;
  weight: FontWeight;
  element?: TypographyElement;
  className?: string;
  onClick?: () => void;
  children: ReactNode;
};

export const Typography: FC<TypographyProps> = ({
  children,
  size,
  weight,
  element: TypographyComponent = 'span',
  className,
  onClick,
  ...props
}) => {
  return (
    <TypographyComponent
      onClick={onClick}
      className={cx(className, `fs-${size} fw-${weight}`)}
      {...props}
    >
      {children}
    </TypographyComponent>
  );
};

import { Screen } from './components/Screen/Screen';
import { ReactQueryClientProvider } from './providers/ReactQueryProvider/ReactQueryProvider';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import './index.css';

function App() {
  return (
    <div>
      <ReactQueryClientProvider>
        <Screen />
      </ReactQueryClientProvider>
    </div>
  );
}

export default App;

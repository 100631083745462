import { FlightDeck } from '../FlightDeck/FlightDeck';
import { Navigation } from '../Navigation/Navigation';
import './Sidebar.css';

export const Sidebar = () => {
  return (
    <div className="sidebar-wrapper">
      <FlightDeck />
      <Navigation />
    </div>
  );
};

import { createDataSource } from '../../mutations/createDataSource/createDataSource';
import { DataSources } from '../../routes/DataSources/DataSources';
import { Button } from '../../ui-kit/components/Button/Button';
import { Input } from '../../ui-kit/components/Input/Input';
import { Select } from '../../ui-kit/components/Select/Select';
import { Typography } from '../../ui-kit/components/Typography/Typography';
import { useConnectionManagement } from './ConnectionManagement.hook';

export const ConnectionManagement = () => {
  const { mutate } = createDataSource();
  const { connection, setConnection } = useConnectionManagement();
  const { host, port, database, user, password, engine } = connection;

  return (
    <div className="p-4">
      <div>
        <Typography size={'lg'} weight={'md'}>
          Existing data sources
        </Typography>
        <div>List goes here</div>
        <DataSources />
      </div>
      <div className="pt-4">
        <Typography size={'lg'} weight={'md'} element={'p'} className="mb-3">
          Add new data source
        </Typography>
        <div className="row">
          <div>
            <div className="mb-2">
              <Input
                className="col-2 me-2"
                value={host}
                name="databaseHost"
                type="text"
                placeholder="Database Host"
                onChange={({ target: { value } }) =>
                  setConnection({
                    ...connection,
                    host: value,
                  })
                }
              />
              <Input
                className="col-2 me-2"
                value={port}
                name="databasePort"
                type="text"
                placeholder="Database Port"
                onChange={({ target: { value } }) =>
                  setConnection({
                    ...connection,
                    port: parseInt(value),
                  })
                }
              />
              <Input
                className="col-2"
                value={database}
                name="databaseName"
                type="text"
                placeholder="Database Name"
                onChange={({ target: { value } }) =>
                  setConnection({
                    ...connection,
                    database: value,
                  })
                }
              />
            </div>
            <div className="mb-2">
              <Input
                className="col-2 me-2"
                value={user}
                name="databaseUser"
                type="text"
                placeholder="Database User"
                onChange={({ target: { value } }) =>
                  setConnection({
                    ...connection,
                    user: value,
                  })
                }
              />
              <Input
                className="col-2"
                value={password}
                name="databasePassword"
                type="text"
                placeholder="Database Password"
                onChange={({ target: { value } }) =>
                  setConnection({
                    ...connection,
                    password: value,
                  })
                }
              />
              <Select
                value={engine}
                onChange={({ target: { value } }: { target: { value: string } }) =>
                  setConnection({
                    ...connection,
                    engine: value,
                  })
                }
                name="databaseEngine"
              >
                <option value="postgres">Postgres</option>
                <option value="mysql">MySQL</option>
              </Select>
            </div>
          </div>
          <div className="mt-2">
            <Button
              onClick={() =>
                mutate({
                  user,
                  password,
                  database,
                  host,
                  port,
                })
              }
            >
              Save data source
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
